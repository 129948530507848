.goBackButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    /* width: 30px;
    height: 30px; */
    border: 0px;
    font-size: 14px;
    font-weight: 600;
    /* Spruce */
    background: #6387C5;
    /* 2dp • Button */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: white;
}

.goBackButton:hover {background-color: #6387C5CC}

.goBackButton:active {background-color: #6387C5E6}

.AnnotatorHeader {
    width: 100%;
    height: 60px;
    /* background: violet; */
    display: flex;
    margin-bottom: 10px;
}

.AnnotatorTool {
    width: 100%;
    height: calc(100% - 50px - 20px);
    /* annotator header height: 100px margin: 20px */
    /* background: #ffffff70; */
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: hidden;
    position: inherit;
}

.topSVG {
	background-color: transparent;
	/*top: 86px; toolbar height + top bar height + margin*/
	position: absolute;
}

.canvas {
  position: absolute;
}

.AnnotatorContainer{
    height: 100%;
    width: 1150px;
    position: relative;
    /* overflow: hidden; */
}

.ScaleDiv {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 10px;
}

.CenterSection {
    display: flex;
    padding: 10px;
    /* border: 1px solid #6387C5; */
    border-radius: 4px;
    width: calc(100% - 345px);
    overflow-x: scroll;
}

.ScaleText {
    padding: 5px 8px;
    border: 1px solid #6387C5;
    margin-right: 7px;
    color: #6387C5;
    border-radius: 4px;
}

.Leadname {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-weight: 800;
    font-size: 20px;
    width: fit-content;
    white-space:nowrap;
    color: #6387C5;
}