/* Annotator Style Sheet - AliveCor Toolkit */

.root {
    display:flex;
}

.TableSelected {
    background-color: #6387C57d;
}

.TableCompleted {
    background-color: #77DD777d;
}

.TableIncomplete {
    background-color: #FF69617d;
}

.disableselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge*/
     user-select: none;          /* Non-prefixed version, currently 
                                    not supported by any browser */
  }

.title {
    font-weight: 800;
}

.text {
    font-weight: 500;
}