/* UploadTask Style Sheet - AliveCor Toolkit */

.root {
    display:flex;
    width: 100%;
    height: 80vh;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    overflow-y: scroll;
    padding : 1rem;
}

.uploadContainer {
    border-style: dashed;
    text-align: center;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: 2px dashed #6387C5;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
  }

.root::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
  
.root::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .2);
}