.grid {
  stroke-width: 1px;
  stroke: #AE1E08;
  shape-rendering: crispEdges;
  stroke-opacity: .2;
  background-color: #fff;
}
.xGridSmall {
  stroke-width: 1px;
  stroke: #AE1E08;
  shape-rendering: crispEdges;
  stroke-opacity: .2;
}

.xGrid {
  stroke-width: 1px;
  stroke: #AE1E08;
  shape-rendering: crispEdges;
  stroke-opacity: .2;
}
.xGridDark {
  stroke-width: 1px;
  stroke: #AE1E08;
  shape-rendering: crispEdges;
  stroke-opacity: .2;
}
.yGrid {
  stroke-width: 1px;
  stroke: #AE1E08;
  shape-rendering: crispEdges;
  stroke-opacity: .2;
}
.yGridDark {
  stroke-width: 1px;
  stroke: #AE1E08;
  shape-rendering: crispEdges;
  stroke-opacity: .4;
}
