.Container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: fit-content;
    overflow-y: scroll;
    overflow-x: scroll;
    align-content: flex-start;
    flex-wrap: nowrap;
}

.LeftColumn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
}

.RightColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* background-color: blue; */
    align-content: flex-start;
    flex-wrap: nowrap;
}

.buttonSpace {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.openLeadButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 30px;
    height: 30px;
    border: 0px;
    /* Spruce */
    background: #6387C5;
    /* 2dp • Button */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.openLeadButton:hover {background-color: #6387C5CC}

.openLeadButton:active {background-color: #6387C5E6}
