.FullWidthECGPreviewContainer{
    background: #fff;
    width: 100%;
    /* Remove these to build the actual component */
    align-content: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    overflow: overlay;
}

.eKGPreview {
}
.previewGridNoTranslate {
  width: 600px;
  transform-origin: 0 0;
  background-color: #fff;
}

.loader{
    /* background: #2d9f86cc; */
}

.LeadTitle{
    font-weight:600;
    color: #000;
    fill: #000;
    font-family: Work Sans Roboto,Helvetica,Arial,sans-serif;
}