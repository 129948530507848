.beat{
    fill-opacity: 0.85 ;
}

.beat:hover{
    fill-opacity: 1 ;
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.493); 
    width: 1000px;
}

.LeadTitle {
    font-size: 15px;
    /* font-weight: 600; */
    color: #000;
    fill: #000;
    /* font-family: 'Soleil', 'Helvetica Neue', helvetica, arial, sans-serif; */
    cursor: pointer;
}

.LeadTitleDelete {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    fill: #000;
    font-family: 'Soleil', 'Helvetica Neue', helvetica, arial, sans-serif;
    cursor: pointer;
}