/* Context Menu */
.dropdownContent {
  position: absolute;
  background-color: #ffffff;
  min-width: 120px;
  height: 130px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 6px 12px rgba(0, 0, 0, 0.12),
    0px 1px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1000;
  overflow: auto;
}

.dropdownContent a {
  color: #142A39;
  padding: 5px 12px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdownContent a:hover {
  background-color: #e2e7e3;
}
