/* OpenEcg Style Sheet - AliveCor Toolkit */

.root {
    display:flex;
}

.gridPanel{
    display: flex;
    height: 75vh;
    width: 100%;
  }

.uploadContainer {
    border-style: dashed;
    text-align: center;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    border: 2px dashed #6387C5;
    margin: 1vh 0;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
  }
