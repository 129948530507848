/* MainTool Style Sheet - AliveCor Toolkit */

.mainContainer {
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

    /* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    }

    ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #fdff;
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    } */
}

.header {
    /* background: #fff; */
    box-shadow: 0 3px 6px rgba(0,0,0,0.05), 0 3px 6px rgba(0,0,0,0.10);
    width: 100vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.hLeft {
    margin-left: 5px;
    margin-right: 5px;
    height: 50px;
    width: 50px;
}

.logo {
    padding: 10px;
    height: 100%;
    width: auto;
}


.hCenter {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.hRight {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.hLink {
    font-size: 20px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
    color: #6387c5;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30vw; 
}

.toolContainer {
    width: 100vw;
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}
/* Recording History */
.tool1Col {
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: calc(100vh - 65px - 10px);
    min-width: 300px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

/* Recording/The Tool */
.tool2Col {
    height: calc(100vh - 65px);
    width: 1000px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.tool2Col1Row {
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: 50px;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

}
.tool2Col2Row {
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: calc(100vh - 65px - 155px);
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    overflow: scroll;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge*/
     user-select: none;          /* Non-prefixed version, currently 
                                    not supported by any browser */
}
.tool2Col3Row {
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: 75px;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    
}

/* Recording Details */
.tool3Col {
    /* background: #fff; */
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    height: calc(100vh - 65px - 10px);
    min-width: 350px;
    width: calc(100vw - 1000px - 200px);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}


.disableselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge*/
     user-select: none;          /* Non-prefixed version, currently 
                                    not supported by any browser */
  }