.Container{
    display: flex;
    flex-direction: row;
  }
  
  .box {
    display: flex;
    overflow-y: hidden;
    /* border: 1px solid #6387C5; */
  }
  .leadWindowAfter, .leadWindowBefore {
    position: relative;
    border: none;
  }


  .leadWindowBefore::before,
  .leadWindowAfter::after {
    position: absolute;
    content: "";
    width: 10px;
    height: calc(50px * var(--ECGScale));
  }

  .leadWindowBefore::before {
    left: 0;
    top: calc((200px - 50px) * var(--ECGScale) / 2);
    border-left: 2px solid #292929b6;
  }

  .leadWindowAfter::after {
    right: 0;
    bottom: calc((200px - 50px) * var(--ECGScale) / 2);
    border-right: 1px solid #292929b6;
    border-radius: ;
  }

  .fullLead {
    display: flex;
    height: 200px * var(--ECGScale);
    width: 100%;
    /* border-bottom: 1px solid #6387C5;
    border-left: 1px solid #6387C5;
    border-right: 1px solid #6387C5; */
  }