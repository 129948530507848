.rrPlot {
    width: 100%;
    /* height: calc(100% - 50px - 20px); */
    /* annotator header height: 100px margin: 20px */
    /* background: #ffffff70; */
    /* background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
    /* overflow: hidden; */
    /* position: inherit; */
  }

.rrPlotTitle {
    text-align: center;
    position: absolute;
    width: 75vw;
  }
.canvas {
  position: absolute;
}

.topSVG {
	background-color: transparent;
	position: absolute;
  fill: #6387C5;
}
  