.goBackButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    /* width: 30px;
    height: 30px; */
    border: 0px;
    font-size: 14px;
    font-weight: 600;
    /* Spruce */
    background: #6387C5;
    /* 2dp • Button */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: white;
}

.goBackButton:hover {background-color: #6387C5CC}

.goBackButton:active {background-color: #6387C5E6}


.Leadname {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 1em;
    white-space:nowrap;
    color: #6387C5;
}


.ScaleText {
    border: 1px solid #6387C5;
    color: #6387C5;
    border-radius: 4px;
    padding: 5px 8px;
    font-weight: 600;
}